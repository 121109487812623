import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Helmet } from "react-helmet";
import { CgArrowRight } from "react-icons/cg";

const IndexPage = () => {
	return (
		<main>
			<Helmet>
				<title>Home Page</title>
			</Helmet>
			<section className="container">
				<div className="flex flex-col space-y-2 pt-6 items-center">
					<h1>Hi, I'm Kyle Gill</h1>
					<p className="max-w-lg text-center leading-6 text-zinc-500">
						I'm a software engineer. I love learning cool things, building cool
						things, and listening to cool music.
					</p>
				</div>
				<div className="flex flex-row justify-center space-x-4 py-7">
					<Link to="/about" className="btn btn-primary">
						More about me
					</Link>
					<Link to="https://twitter.com/gill_kyle" className="btn btn-outline">
						Follow me on Twitter
					</Link>
				</div>
				<div className="relative my-10 sm:my-16">
					<h2 className="text-center mb-3">Latest</h2>
					<Link
						className="flex p-2 group bg-white rounded-3xl justify-center"
						to="/essays/api-boundaries"
					>
						<div className="flex border-dashed border hover:border-zinc-300 p-4 rounded-3xl text-zinc-400 text-md font-normal items-center w-max">
							<div className="bg-amber-50 text-amber-600 flex-none mr-3 font-semibold px-3 py-2 rounded-lg">
								New!
							</div>{" "}
							<div className="mr-1 text-sm md:text-md">
								Read recently published{" "}
								<span className="italic font-semibold text-amber-600">
									API Boundaries
								</span>
							</div>
							<div className="text-amber-600 transform transition-transform group-hover:translate-x-1">
								<CgArrowRight size={24} />
							</div>
						</div>
					</Link>
				</div>
				<div className="flex justify-center p-4">
					<StaticImage
						alt="Globe illustration"
						src="../images/globe.png"
						layout="CONSTRAINED"
						maxWidth={540}
						placeholder="TRACED_SVG"
						formats={["auto", "webp", "avif"]}
					/>
				</div>
			</section>
		</main>
	);
};

export default IndexPage;
